import { Route, Switch } from 'react-router-dom';

import { Page, View, ListAllIcons } from '@adac/core-view';

export default function MainRouterContainer(): JSX.Element {
  return (
    <Page border="cyan" overflow="scroll" fullHeight>
      <View title="true">DEVELOPER TOOLS</View>
      <Switch>
        <Route path="/dev/list/icons" component={ListAllIcons} />
      </Switch>
    </Page>
  );
}
