import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';

import {
  StaticMenuList,
  View,
  Icon,
  StaticMenuListStyles,
} from '@adac/core-view';

import StoresContext from '../../stores';
import { useIsInsuranceProduct } from '../../hooks/useIsInsuranceProduct';

const NavBarStyle = styled(View)`
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;

  ${StaticMenuListStyles} {
    flex: 0 0 auto;
  }
`;

export default observer(() => {
  const {
    ui: uiStore,
  } = React.useContext(StoresContext);

  const isInsurance = useIsInsuranceProduct();

  if (isInsurance) return null;

  const menulinks = [
    {
      id: '',
      route: '+498002876687',
      title: '', // DLC Zentrale
      image: isInsurance ? undefined : <Icon name="phone" round invert color="white" />,
    },
  ];

  return (
    <NavBarStyle padding>
      <StaticMenuList items={menulinks} uiStore={uiStore} />
    </NavBarStyle>
  );
});
