import styled from 'styled-components';

import {
  SubTitle, FlexView, IconStyle, Icon,
} from '@adac/core-view';

import { View } from '../../ui';
import { QuestionProps } from './SurveyOverlays';

const Title = styled(SubTitle)`
  margin-bottom: 56px;
`;

const Smilies = styled(View)`
  margin-bottom: 50px;
  > ${IconStyle} {
    padding: 0px 12px;
  }
`;

interface Props extends QuestionProps {
  onAnswered: (smileyNum: number) => void;
}

const SmileyFeedbackOverlay = ({ onAnswered, question }: Props) => (
  <View padding>
    <Title center big>{question.text2}</Title>
    <FlexView center>
      {
        [5, 4, 3, 2, 1].map(i => (
          <Smilies onClick={() => onAnswered(i)}>
            <Icon name={`smiley-${i}`} />
          </Smilies>
        ))
      }
    </FlexView>
  </View>

);

export default SmileyFeedbackOverlay;
