import styled from 'styled-components';
import {
  Icon, LinkStyle, Title, ChildrenProps,
} from '@adac/core-view';
import { __ } from '@adac/core-model';

const LandingContainer = styled.div`
  height: ${p => p.theme.getMainHeight}px;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const DefaultLandingContent = () => (
  <>
    <Title marginRight="10px">{__('I need help')}</Title>
    <LinkStyle href="tel:123-456-7890"><Icon name="phone" round invert color="white" /></LinkStyle>
  </>
);

export const Landing = ({ children = <DefaultLandingContent /> }: ChildrenProps) => (
  <LandingContainer>
    {children}
  </LandingContainer>
);
