import { useContext } from 'react';
import { Route } from 'react-router-dom';
import { observer } from 'mobx-react';

import {
  useLocation, DialogStyle, findMatchingRoute, RouteSwitcher, OverlayContentWrapper,
} from '@adac/core-view';

import StoreContext from '../../stores';

import ComingSoon from '../overlays/ComingSoon';
import SurveyOverlays from '../overlays/SurveyOverlays/SurveyOverlays';

export default observer(() => {
  const stores = useContext(StoreContext);
  const location = useLocation();
  const { getPrefixedLink } = location;

  const routes: JSX.Element[] = [
    <Route
      path={getPrefixedLink('/survey')}
      exact
      render={() => (
        <SurveyOverlays />
      )}
    />,
  ];

  const foundMatchingRoute = !!findMatchingRoute({ location, routes });

  const isComingSoon = location.search.match(/[?&]coming-soon(\/|&|$)/gi) !== null;
  const { showOverlay, OverlayContent, isFullPageOverlay } = stores.ui;
  const hasValidRoute = foundMatchingRoute;
  const open = isComingSoon || hasValidRoute || showOverlay;

  return (
    <DialogStyle open={open} column end="true">
      {hasValidRoute && <RouteSwitcher routes={routes} />}
      {isComingSoon && <ComingSoon />}
      {showOverlay && <OverlayContentWrapper fullPage={isFullPageOverlay}>{OverlayContent}</OverlayContentWrapper>}
    </DialogStyle>
  );
});
